/* ##################################### global styles ##################################### */
:root {
  --color-1: #F5EFEF;
  --color-2: rgb(84, 84, 84);
  --color-3: rgb(255, 255, 255);
  --color-4: rgb(0, 0, 0);
  --font-1: 'Italianno', cursive;
  --font-2: 'Raleway', sans-serif;
}

.App {
  text-align: center;
}

#tempId {
  background-color: green;
}

.title {
  font-size: 40px;
  font-family: var(--font-1)
}

.body {
  font-size: 18px;
  font-family: var(--font-2);
}

.botPad {
  margin-bottom: 20px;
}

img ,#packageTitleImg{
  border-radius: 35px;
}

/* ##################################### page styles ##################################### */

/* Home page */

#welcomeTitleContainer {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

#welcomeImg {
  background-color: var(--color-2);
  width: 50vw;
  min-width: 375px;
  height: 600px;
  margin: 20px 0px;
  object-fit: cover;
  object-position: 50% 50%;
}

#welcomeMsg {
  max-width: 40vw;
}

#homePackagesContainer {
  margin: 100px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

#homePackagesMsg {
  max-width: 30vw;
}

#homePackagesImg {
  background-color: var(--color-2);
  width: 400px;
  height: 400px;
  object-fit: cover;
  /* background-image: url('../src/assets/drink2.jpeg'); */
}

.homeTitle {
  font-size: 50px;
  font-family: var(--font-1);
}

.homeDescription {
  font-size: 20px;
  font-family: var(--font-2);
}

@media screen and (max-width: 600px) {

  #welcomeMsg,
  #homePackagesMsg {
    max-width: 90%;
    margin: 10px;
  }
}

/* Packages Page */

#PackagesTitleSection {
  position: relative;
  margin-top: 5vw;
}

#packageTitleImg {
  margin-left: 5vw;
  background-image: url('../src/assets/pour.jpeg');
  width: 50vw;
  background-position: center;
  height: 30vw;
  background-color: var(--color-2);
  background-size: cover;
}

#packageDecContainer {
  padding: 20px;
  background-color: var(--color-1);
  border-radius: 35px;
  position: relative;
  top: -9vw;
  right: -25vw;
  max-width: 60vw;
  min-width: 300px;
}

#packageVariationsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.packageVarient {
  width: 29%;
  min-width: 300px;
  margin: 10px 0px 50px 0px;
  padding: 20px;
  border-radius: 35px;
  background-color: var(--color-1);
}

.varientTitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#PackagesTravelContainer {
  min-width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 20px;
  border-radius: 35px;
  align-items: center;
  background-color: var(--color-1);
  padding: 20px;
}

#packageAddOnSection {
  margin-bottom: 20px;
  padding: 20px;
  height: fit-content;
}

#packageAddOnContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
}

.addOn {
  background-color: var(--color-1);
  margin: 5px;
  width: 20vw;
  margin: auto;
  padding: 10px;
  border-radius: 35px;
  min-width: 350px;
}

.disclaimer{
  color: rgb(102, 94, 94);
  max-width: 85%;
  margin: 0 auto;
  padding: 10px 0 ;
}

/* Menu page */

#menuPage {
  display: grid;
  grid-template-rows: 1fr .5fr .5fr .5fr .5fr;
  gap: 20px;
}

#menuWelcomeSection {
  background-color: var(--color-1);
  margin: 20px;
  display: flex;
  padding: 2vw;
  padding-left: 6vw;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
  align-items: left;
  min-height: 100%;
}

#menuTitleImg1 {
  background-color: rgba(0, 0, 0, 0.485);
  width: 50vw;
  height: 30vw;
  margin-left: 3vw;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 35px;

}

#menuTitleImg2 {
  position: absolute;
  right: 15vw;
  background-color: rgba(0, 128, 0, 0.356);
  width: 30vw;
  height: 45vw;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 35px;
}

.menuWelcome1Grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#menuFlex1 {
  display: flex;
  flex-direction: column;
}

#menuWelcomMsg {
  align-self: flex-start;
}

#mm2 {
  margin-left: 10vw;
}

#mm3 {
  margin-left: 20vw;
}

.menuOptions {
  margin: 0 10vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.menuOption {
  display: flex;
  min-width: 150px;
  margin: 10px;
  width: 25%;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.menuTitleSection {
  margin-bottom: 40px;
}

#menuDisclaimerSection {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

@media screen and (max-width: 600px) {
  .menuOptions {
    margin: 0 30vw;
  }
}


/* FAQ page */

#faqWelcome {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accentLine {
  margin: 15px;
  width: 25%;
  border-bottom: 1px solid black;
}

.questionContainer {
  background-color: var(--color-1);
  padding: 10px;
  margin: 15px 40px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question {
  font-size: 25px;
  font-family: var(--font-1);
}

.answer {
  font-size: 20px;
}

#faqFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

#faqLogo {
  width: 150px;
}

/* contact page */

#contactWelcomeSection {
  margin: 5vw 10vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

#contactWelcomeMsgContainer {
  padding-bottom: 20px;
}

#contactWelcomeImgContainer {
  display: flex;
  align-items: center;
  max-width: 50vw;
  justify-content: center;
}

#contactWelcomeImg {
  width: 25vw;
  height: 25vw;
  background-color: var(--color-2);
  z-index: 1;
  min-width: 200px;
  min-height: 200px;
  object-fit: cover;
  object-position: 50% 90%;
}

#contactWelcomeaccent {
  position: relative;
  right: -18vw;
  top: -2vw;
  width: 25vw;
  height: 25vw;
  z-index: 0;
  min-height: 200px;
  min-width: 200px;
  background-color: var(--color-1);
}

#contactContainer {
  background-color: var(--color-1);
  margin: 1vw 6vw;
  border-radius: 35px;
  padding: 1vw;
}

.emailAlert {
  position: sticky;
  /* Fixed position */
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 999;
}


@media screen and (max-width: 600px) {
  #contactWelcomeSection {
    flex-wrap: wrap;
  }

  #contactWelcomeImg {
    margin-right: 25vw;
  }
}

/* ##################################### component styles ##################################### */


/* nav style */
#navContainer {
  background-color: var(--color-1);
  font-size: 20px;
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%; */
}

.navTitleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.socials {
  display: flex;
  flex-wrap: wrap;
  width: 15%;
  align-items: center;
  justify-content: space-around;
}

.socials button {
  background-color: rgb(214, 211, 204);
  border: none;
  text-decoration: none;
  color: black;
  font-size: 18px;
}

.navTitleContainer img {
  margin: 20px 30px;
  width: 13vh;
}

.navbar {
  list-style: none;
  border-top: 1px solid black;
}

.linksContainer a {
  text-decoration: none;
  color: black;
}

.linksContainer a:hover {
  color: rgb(196, 193, 187);
}

/* Footer styles */
#footerContainer {
  padding: 20px 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-1);
}

#footerContainer img {
  width: 13vh;
}

#footerContainer button {
  margin-right: 10px;
}

.location {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid black;
  padding-left: 10px;
  width: fit-content;
}

#locationsTitle {
  font-size: 20px;
}